<template>
  <div class="game-period">
    <div v-if="isTitleVisible" class="game-period__title">
      Tiempo:
    </div>
    <div class="game-period__radio-group">
      <div
        v-for="(period, index) of gamePeriods"
        :key="index"
        class="game-period__radio-group__field"
        :class="{ 'game-period__radio-group__field--active': gamePeriod === period.value }"
        @click="onFieldClick(period.value, period.text)"
      >
        {{ period.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { RightBetTime } from '@/utils/analytics';

export default {
  name: 'RadioGamePeriod',
  props: {
    isTitleVisible: {
      type: Boolean,
      default: true,
    },
    section: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      gamePeriod: 1,
      gamePeriods: [
        {
          text: '1t',
          value: 1,
        },
        {
          text: '2t',
          value: 2,
        },
        {
          text: 'total',
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapState('betsGeneral', ['filterGamePeriod']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  watch: {
    filterGamePeriod: {
      immediate: true,
      handler(newValue) {
        this.gamePeriod = newValue;
      },
    },
  },
  methods: {
    ...mapMutations('betsGeneral', ['setFilterGamePeriod']),
    onFieldClick(value, text) {
      if (this.gamePeriod !== value) {
        this.gamePeriod = value;
        this.setFilterGamePeriod(value);
        RightBetTime({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
          value: text,
          section: this.section,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
$color1: #f4f4f4;
$color2: #82bf1d;
$border-radius: 8px;

.game-period {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: Roboto-Medium, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
  }

  &__radio-group {
    display: flex;
    justify-content: center;
    align-items: center;

    &__field {
      width: 4.75rem;
      border: solid 2px #cbee6b;
      padding: 0.2rem 0;
      margin: 0 0.1rem;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.81rem;
      font-weight: bold;

      &--active {
        background-color: #cbee6b;
      }

      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
      }

      &:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }
}
</style>
